<template>
  <div
    ref="$logoBanner"
    :class="[!textColor && 'text-black']"
    :style="{
      color: textColor ? textColor : 'inherit',
      backgroundColor: backgroundColor ? backgroundColor : 'inherit',
    }"
    class="h-[14.75rem] md:h-[21.5rem] 3xl:h-[32.25rem] text-m-lg md:text-lg flex items-stretch p-4 md:px-6 md:py-10 3xl:px-12 3xl:py-10"
  >
    <div
      :class="[
        logoVerticalAlignment &&
          logoVerticalClassNamesResolver[logoVerticalAlignment],
      ]"
      class="relative h-auto w-full flex justify-center"
    >
      <div
        :class="[
          textHorizontalPositionClassNamesResolver[topTextHorizontalAlignment],
          textAlignClassNamesResolver[topTextAlignment],
        ]"
        class="absolute top-0 font-medium"
        v-html="topText"
      ></div>
      <LogoSvg
        v-element-visibility="onElementVisibility"
        class="mx-12 md:mx-10"
        :color="textColor ? textColor : 'black'"
      />
      <div
        :class="[
          textHorizontalPositionClassNamesResolver[
            bottomTextHorizontalAlignment
          ],
          textAlignClassNamesResolver[bottomTextAlignment],
        ]"
        class="absolute bottom-0 font-medium"
        v-html="bottomText"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, onUnmounted, onMounted } from "vue";
import { vElementVisibility } from "@vueuse/components";
import { useLogoBannerDetailsStore } from "@/stores";
import LogoSvg from "@/components/atoms/LogoSvg";
import type { HorizontalAlignment, VerticalAlignment } from "@/types";

const props = defineProps<{
  logoVerticalAlignment?: VerticalAlignment;
  topText: string;
  topTextHorizontalAlignment: HorizontalAlignment;
  topTextAlignment: HorizontalAlignment;
  bottomText: string;
  bottomTextHorizontalAlignment: HorizontalAlignment;
  bottomTextAlignment: HorizontalAlignment;
  textColor?: string;
  backgroundColor?: string;
  noPadding?: boolean;
}>();

const { backgroundColor } = toRefs(props);

const {
  logoBannerBackgroundColor,
  areSomeLogoBannersVisible,
  logoBannersOnPage,
} = toRefs(useLogoBannerDetailsStore());

const $logoBanner = ref<HTMLElement | null>(null);

if (typeof useHead !== "undefined") {
  useHead({
    bodyAttrs: {
      style: () => {
        const defaultColor = "#ffffff";
        const newBackgroundColor =
          logoBannerBackgroundColor.value || defaultColor;
        return `background-color: ${hexToRGB(newBackgroundColor, 0.5)}`;
      },
    },
  });
}

const onElementVisibility = (state: boolean) => {
  state
    ? areSomeLogoBannersVisible.value.push(true)
    : areSomeLogoBannersVisible.value.pop();
};

const logoVerticalClassNamesResolver: Record<VerticalAlignment, string> = {
  top: "items-start",
  center: "items-center",
  bottom: "items-end",
};

const textHorizontalPositionClassNamesResolver: Record<
  HorizontalAlignment,
  string
> = {
  left: "left-0",
  right: "right-0",
  center: "",
};

const textAlignClassNamesResolver: Record<HorizontalAlignment, string> = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
};

onMounted(() => {
  logoBannersOnPage.value.push(backgroundColor.value || "#ffffff");
});

onUnmounted(() => {
  logoBannersOnPage.value.forEach((iteratedBackgroundColor, index) => {
    if (iteratedBackgroundColor === backgroundColor.value) {
      logoBannersOnPage.value.splice(index, 1);
    }
  });
});
</script>
